import { loggerServicePlugin } from '@/features/core/logger';
import { sleep } from './event-loop-waiters';

export const retryFunction = async <T>(
  fn: () => Promise<T>,
  retries: number,
  intervals: number[],
  retryAttemptNumber = 1,
): Promise<T | void> => {
  const currentAttemptInterval =
    intervals.length > retryAttemptNumber
      ? intervals[retryAttemptNumber - 1]
      : intervals[intervals.length - 1];

  const nextRetryAttemptNumber = retryAttemptNumber + 1;
  const waitTimeString =
    currentAttemptInterval > 0
      ? `waiting for ${Math.round(
          currentAttemptInterval / 1000,
        )}s before retrying`
      : 'retrying immediately';
  console.warn(`Refreshing the user token failed, ${waitTimeString}`);

  if (currentAttemptInterval > 0) {
    await sleep(currentAttemptInterval);
  }
  try {
    return await fn();
  } catch (error) {
    if (nextRetryAttemptNumber > retries) {
      loggerServicePlugin
        .get()
        .error(`Failed after ${retryAttemptNumber} retries`, error);
      return;
    }
    return retryFunction(fn, retries, intervals, nextRetryAttemptNumber);
  }
};
