import { errorPlugin } from '@/features/core/errors';
import { Order, OrderPluginDto } from '@/features/orders';
import type { StartPickingSwitchers } from '@/features/orders/types';
import { PluginPipeline } from '@ads/plugin-pipeline';
import { PipelineExecutionError } from '@ads/plugin-pipeline/build/pipeline-execution-error';
import {
  PickingAlreadyStartedError,
  PickingCancelOrCompletedError,
} from '../errors';
import { startPickingRegisterPlugin } from './startPickingRegisterPlugin';
import {
  PerformanceThresholdEnum,
  usePerformanceTracker,
} from '@/features/performance-tracker';

export async function startPickingProcess(
  order: Order,
  switchers: StartPickingSwitchers,
): Promise<void> {
  const { startTracking, stopTracking } = usePerformanceTracker();

  try {
    startTracking(
      `start-picking-process-${order.id}`,
      PerformanceThresholdEnum.ROUTE_CHANGE,
    );

    let startOrderPlugin = new PluginPipeline<OrderPluginDto>();

    startOrderPlugin = startPickingRegisterPlugin(
      order,
      switchers,
      startOrderPlugin,
    );

    await startOrderPlugin.execute({
      order,
    });
  } catch (error) {
    handleStartPickingError(error, switchers);
  } finally {
    stopTracking(`start-picking-process-${order.id}`);
  }
}

const isPipelineExecutionError = <T>(
  error: unknown,
): error is PipelineExecutionError<T> => {
  return error instanceof PipelineExecutionError;
};

const isPickingAlreadyStartedError = (
  error: unknown,
): error is PickingAlreadyStartedError => {
  return error instanceof PickingAlreadyStartedError;
};

const isPickingCancelledOrCompletedError = (
  error: unknown,
): error is PickingCancelOrCompletedError => {
  return error instanceof PickingCancelOrCompletedError;
};

const handleStartPickingError = (
  error: Error,
  switchers: StartPickingSwitchers,
): void => {
  switchers.isProcessing.value = false;
  switchers.isUserTokenRefreshing.value = false;

  if (!isPipelineExecutionError(error)) {
    errorPlugin.get().handle(error);
    return;
  }

  if (isPickingCancelledOrCompletedError(error.originalError)) {
    switchers.isPickingCancelledOrCompleted.value = true;
    return;
  }

  if (isPickingAlreadyStartedError(error.originalError)) {
    switchers.isOrderAlreadyStarted.value = true;
    return;
  }

  errorPlugin.get().handle(error.originalError);
};
