import { Ref } from 'vue';
import { Order } from '@/features/orders';

export interface UseCheckInBanner {
  loading: Ref<boolean>;
  goToCheckInOrder: (checkedInOrder: Order) => Promise<void>;
}

export enum CheckInBannerContextEnum {
  PICKING_FLOW = 'picking-flow',
  ORDER_OVERVIEW = 'order-overview',
}

export type UseStartHandoverProcess = {
  startHandoverProcess: (order: Order, switcher: Ref<boolean>) => Promise<void>;
};
