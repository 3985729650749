import { apiPlugin } from '@/features/core/api';
import { eventBusServicePlugin } from '@/features/core/event-bus';
import {
  ExecPluginFactory,
  MultiPluginFactory,
  ProviderPluginFactory,
} from '@/features/core/plugin';
import { CustomerCheckInAcknowledgedEvent } from '@/features/orders/checkin/events';
import { FirebaseProviderConfig, FirebaseService } from './firebase';
import {
  AcknowledgeCheckInNotificationService,
  NoopPushService,
  PushServiceImpl,
} from './services';
import { PushService } from './types';

export const pushNotificationServicePlugin = ProviderPluginFactory.create<
  PushService,
  FirebaseProviderConfig
>({
  key: Symbol('pushNotificationService'),
  defaultFactory: {
    create: (app, options) => {
      return process.env.NODE_ENV === 'production' &&
        process.env.VUE_APP_SKIP_PUSH_NOTIFICATION_REGISTRATION !== 'true'
        ? new PushServiceImpl(new FirebaseService(options))
        : new NoopPushService();
    },
  },
});

export const acknowledgeCheckInNotificationServicePlugin =
  ProviderPluginFactory.create<AcknowledgeCheckInNotificationService>({
    key: Symbol('acknowledgeNotification'),
    defaultFactory: {
      create: () => {
        return new AcknowledgeCheckInNotificationService(apiPlugin.get());
      },
    },
  });

export const acknowledgeCheckInNotificationExecutePlugin = ExecPluginFactory.fn(
  () => {
    eventBusServicePlugin
      .get()
      .on(CustomerCheckInAcknowledgedEvent, ({ orderId }) => {
        void acknowledgeCheckInNotificationServicePlugin
          .get()
          .sendAcknowledgeRequest(orderId);
      });
  },
);

export const pushNotificationPlugin = MultiPluginFactory.with({
  pushNotificationServicePlugin,
  acknowledgeCheckInNotificationServicePlugin,
  acknowledgeCheckInNotificationExecutePlugin,
});
