import { PipelinePlugin } from '@ads/plugin-pipeline';
import { ordersServicePlugin } from '../services';
import { OrderPluginDto } from '../types';

export class PausePickingPlugin implements PipelinePlugin<OrderPluginDto> {
  constructor(private orderId: string) {}

  public async execute(
    dataTransferObject: OrderPluginDto,
  ): Promise<OrderPluginDto> {
    if (!this.orderId) {
      return dataTransferObject;
    }

    await ordersServicePlugin.get().pauseOrderById(this.orderId);

    return dataTransferObject;
  }
}
