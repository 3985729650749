import { Ref } from 'vue';
import { PluginPipeline } from '@ads/plugin-pipeline';
import { errorPlugin } from '@/features/core/errors';
import router from '@/features/core/router';
import {
  BooleanSwitcherPlugin,
  HandleRestrictedOrderPlugin,
  NavigateToTargetRoutePlugin,
  Order,
  OrderPluginDto,
  SaveOrderToStorage,
  SetCustomerNoteAsTargetRoutePlugin,
  SetPickingEventsAsAlreadyPatchedPlugin,
  SetTargetRoutePlugin,
  TrackOrderEventsPlugin,
} from '@/features/orders';
import { ordersServicePlugin } from '@/features/orders/services';
import { PipelineExecutionError } from '@ads/plugin-pipeline/build/pipeline-execution-error';
import {
  PerformanceThresholdEnum,
  usePerformanceTracker,
} from '@/features/performance-tracker';
import { AcknowledgeCheckInNotificationPlugin } from '@/features/push-notification/plugins/acknowledge-check-in-notification';
import { eventBusServicePlugin } from '@/features/core/event-bus';

export async function startHandoverProcess(
  order: Order,
  switcher: Ref<boolean>,
): Promise<void> {
  const { startTracking, stopTracking } = usePerformanceTracker();
  try {
    const startOrderPlugin = new PluginPipeline<OrderPluginDto>();
    startTracking(
      `start-handover-process-${order.id}`,
      PerformanceThresholdEnum.ROUTE_CHANGE,
    );
    startOrderPlugin.registerPlugins([
      new HandleRestrictedOrderPlugin(),
      new BooleanSwitcherPlugin(switcher, true),
      new SetPickingEventsAsAlreadyPatchedPlugin(),
      new SetTargetRoutePlugin({
        name: 'bags-collection',
        params: {
          id: order.id,
        },
      }),
      new TrackOrderEventsPlugin(),
    ]);

    startOrderPlugin.registerPlugins(
      [
        new AcknowledgeCheckInNotificationPlugin(
          ordersServicePlugin.get(),
          eventBusServicePlugin.get(),
        ),
      ],
      (dto) =>
        dto.order.checkIn?.isCheckedIn === 1 &&
        !dto.order.checkIn?.isAcknowledged,
    );

    startOrderPlugin.registerPlugins([
      new SaveOrderToStorage(),
      new SetCustomerNoteAsTargetRoutePlugin(),
      new NavigateToTargetRoutePlugin(),
      new BooleanSwitcherPlugin(switcher, false),
    ]);

    await startOrderPlugin.execute({
      order,
    });
  } catch (error) {
    switcher.value = false;
    if (error instanceof PipelineExecutionError) {
      errorPlugin.get().handle(error.originalError);
    } else {
      errorPlugin.get().handle(error);
    }
    await router.get().push('/');
  } finally {
    stopTracking(`start-handover-process-${order.id}`);
  }
}
