import { ref } from 'vue';
import { Order } from '@/features/orders';
import { CheckInBannerContextEnum, UseCheckInBanner } from '../types';
import { useStartHandoverProcess } from './useStartHandoverProcess';
import {
  PerformanceThresholdEnum,
  usePerformanceTracker,
} from '@/features/performance-tracker';
import { loggerServicePlugin } from '@/features/core/logger';

export const useCheckInBanner = (
  context: CheckInBannerContextEnum,
): UseCheckInBanner => {
  const { startHandoverProcess } = useStartHandoverProcess(context);

  const loading = ref(false);

  const goToCheckInOrder = async (checkedInOrder: Order): Promise<void> => {
    const { startTracking } = usePerformanceTracker();
    startTracking(
      `checkin-banner-clicked`,
      PerformanceThresholdEnum.ROUTE_CHANGE,
    );

    loggerServicePlugin
      .get()
      .info(`CheckIn banner clicked [order ${checkedInOrder.id}]`, {
        context,
      });

    await startHandoverProcess(checkedInOrder, loading);
  };

  return {
    loading,

    goToCheckInOrder,
  };
};
