import { EventBus } from '@/features/core/event-bus';
import { OrderEventNames, OrderPluginDto } from '@/features/orders/types';
import { CustomerCheckInAcknowledgedEvent } from '@/features/orders/checkin/events';
import { PipelinePlugin } from '@ads/plugin-pipeline';
import { OrdersService } from '@/features/orders/services';

export class AcknowledgeCheckInNotificationPlugin
  implements PipelinePlugin<OrderPluginDto>
{
  constructor(
    private orderService: OrdersService,
    private eventBusService: EventBus,
  ) {}
  public async execute(dto: OrderPluginDto): Promise<OrderPluginDto> {
    this.eventBusService.emit(
      new CustomerCheckInAcknowledgedEvent(dto.order.id),
    );

    dto.order = await this.orderService.trackEvent(
      dto.order,
      OrderEventNames.checkin_acknowledged,
      {
        skipSaving: true,
      },
    );

    dto.order.checkIn.isAcknowledged = true;
    return dto;
  }
}
