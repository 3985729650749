<template>
  <div class="wrapper">
    <div class="chip-time" v-if="order?.checkIn?.timestamp">
      <ChipTime :time="order?.checkIn?.timestamp" />
    </div>
    <div class="hero">
      <img
        alt=""
        class="ut-img-contain"
        data-e2e="popup-info-img"
        height="278"
        src="@/assets/images/overview-hero@2x.png"
      />
    </div>
    <div v-if="order" class="note">
      <div class="note__header header">
        <h1 class="note__title header__item">
          {{ $t('pages.customer-note.headline.text') }}
        </h1>
        <h2 class="note__id header__item" data-e2e="order-pickupCode-label">
          {{ isPicking ? order?.orderReference : order?.pickupCode }}
        </h2>
      </div>
      <p class="note__message" data-e2e="order-cartNote-label">
        {{ order?.cartNote }}
      </p>
      <div class="note__footer">
        <Button
          :disabled="processing"
          btnOutline
          data-e2e="start-picking-button"
          @click="goNext"
        >
          {{ $t('pages.customer-note.button-confirm.text') }}
        </Button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import { router } from '@/features/core/router';
import { useOrder } from '@/features/picking';
import ChipTime from '@/features/ui/components/ChipTime.vue';
import Button from '@/features/ui/components/Button.vue';
import { isOrderPicking } from '@/features/orders';
import { usePerformanceTracker } from '@/features/performance-tracker';

export default defineComponent({
  components: {
    ChipTime,
    Button,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { order, loadOrder } = useOrder();
    const { stopTracking } = usePerformanceTracker();
    const processing = ref(false);
    const isPicking = computed(() =>
      !order.value ? true : isOrderPicking(order.value),
    );

    onMounted(async () => {
      await loadOrder(props.id);
      stopTracking(`checkin-banner-clicked`);
    });

    const goNext = async () => {
      if (!order.value) {
        return;
      }

      await router.get().push({
        name: 'bags-collection',
        params: {
          id: props.id,
        },
      });

      processing.value = false;
    };

    return {
      order,
      goNext,
      processing,
      isPicking,
    };
  },
});
</script>
<style lang="scss" scoped>
.wrapper {
  padding: 12px 16px;
}

.hero {
  margin: 60px auto auto;
  width: 235px;
  max-width: 90%;
}

.note {
  padding: 0 8px 28px;
  margin-top: 38px;
  text-align: left;

  &__title,
  &__id {
    font-size: 16px;
    line-height: 1.18em;
  }

  &__title {
    letter-spacing: 0.015em;
  }

  &__id {
    font-weight: 500;
  }

  &__message {
    padding-right: 14px;
    margin-top: 16px;
    line-height: 26px;
  }

  &__footer {
    position: relative;
    z-index: 1;
    margin-top: 29px;
  }
}

.header {
  &__item {
    padding: 9px 0 4px;
    border-bottom: 0.5px solid rgba(182, 196, 223, 0.6);
  }
}

.chip-time {
  position: fixed;
  top: 15px;
  transform: translateX(-50%);
  left: 50%;
}
</style>
