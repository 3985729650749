import { ConfigurationService } from '@/features/configuration';
import { LoggerService } from '@/features/core/logger';
import type { Storage } from '@/features/core/storage';
import { ImageCachingQueueServiceImplementation } from '@/features/imageCachingQueue';
import type { OrderParsePluginDto } from '@/features/orders';
import { OrderLocalStatus } from '@/features/orders/types';
import { hasOrderLocalStatus } from '@/features/orders/helpers';
import { LoadExistingOrderPlugin } from '@/features/orders/plugins/load-existing-order';
import { NormalizeOrderPlugin } from '@/features/orders/plugins/normalize-order';
import { UseExistingOrderAndCompletePipelinePlugin } from '@/features/orders/plugins/use-existing-order-and-complete-pipeline';
import { LoadOrderProductsPlugin } from '@/features/orders/plugins/load-order-products';
import { DefineActionStatusesAttributePlugin } from '@/features/orders/plugins/define-action-statuses-attribute';
import { AddBagsToOrderPlugin } from '@/features/orders/plugins/add-bags-to-order';
import { AddTemperatureClassesToOrderPlugin } from '@/features/orders/plugins/add-temperature-classes-to-order';
import { ClearNotificationPlugin } from '@/features/orders/plugins/clear-notification';
import { NormalizeItemStatusForHandoverPlugin } from '@/features/orders/plugins/normalize-item-status-for-handover';
import { ResettingItemQuantityPlugin } from '@/features/orders/plugins/resetting-item-quantity';
import { NormalizeItemsPlugin } from '@/features/orders/plugins/normalize-items';
import { KeepItemsFromExistingOrderPlugin } from '@/features/orders/plugins/keep-items-from-existing-order';
import { GroupItemsPlugin } from '@/features/orders/plugins/group-items';
import { TrackEventHandoverReadyPlugin } from '@/features/orders/plugins/track-handover-ready-event';
import { CountingOriginalForCancelAndStagedPlugin } from '@/features/orders/plugins/counting-original-for-cancel-and-staged';
import { RestrictedOrderCheckPlugin } from '@/features/orders/plugins/restricted-order-check';
import { DeleteOutstandingSyncsPlugin } from '@/features/orders/plugins/delete-outstanding-syncs';
import { CacheProductImagesPlugin } from '@/features/orders/plugins/cache-product-images';
import { SortItemsWithBagsPlugin } from '@/features/orders/plugins/sort-items-with-bags';
import { SyncSchedulerService } from '@/features/sync-scheduler';
import { PluginPipeline } from '@ads/plugin-pipeline';
import { NotificationTags } from '@/features/push-notification';
import { ManipulateOrderStatesPlugin } from '../plugins/manipulate-order-states';
import { useFeatureToggle } from '@/features/feature-toggle/composables';

export function getParseOrderPipeline(
  storage: Storage,
  imageCachingService: ImageCachingQueueServiceImplementation,
  syncSchedulerService: SyncSchedulerService,
  loggerService: LoggerService,
  configurationService: ConfigurationService,
): PluginPipeline<OrderParsePluginDto> {
  const parseOrderPlugin = new PluginPipeline<OrderParsePluginDto>();

  parseOrderPlugin.registerPlugins([
    new LoadExistingOrderPlugin(storage),
    new NormalizeOrderPlugin(),
  ]);

  /**
   * When a picking is STARTING or CONTINUING, we retrieve the local object from IDB
   * This is mainly used to retrieve the local status of the order when continuing picking
   * For actions that are not related to those, we should parse it again (for syncing reasons)
   **/

  parseOrderPlugin.registerPlugins(
    [new UseExistingOrderAndCompletePipelinePlugin()],
    shouldUseExistingOrderAndCompletePipeline,
  );

  parseOrderPlugin.registerPlugins([
    new LoadOrderProductsPlugin(storage),
    new AddTemperatureClassesToOrderPlugin(),
    new DefineActionStatusesAttributePlugin(),
    new AddBagsToOrderPlugin(configurationService),
  ]);

  parseOrderPlugin.registerPlugins(
    [new ClearNotificationPlugin(NotificationTags.SameDateOrder)],
    (dto: OrderParsePluginDto) => {
      return (
        dto.existingOrder !== undefined &&
        dto.order.localStatus === OrderLocalStatus.PickingInProgress &&
        dto.existingOrder.localStatus === OrderLocalStatus.PickingReady
      );
    },
  );

  parseOrderPlugin.registerPlugins(
    [new ClearNotificationPlugin(NotificationTags.CheckIn)],
    (dto: OrderParsePluginDto) => {
      return (
        dto.existingOrder !== undefined &&
        dto.order.localStatus === OrderLocalStatus.HandoverInProgress &&
        dto.existingOrder.localStatus === OrderLocalStatus.HandoverReady
      );
    },
  );

  parseOrderPlugin.registerPlugins(
    [new NormalizeItemStatusForHandoverPlugin()],
    (dto: OrderParsePluginDto) => {
      return dto.order.localStatus === OrderLocalStatus.HandoverReady;
    },
  );

  parseOrderPlugin.registerPlugins(
    [new ResettingItemQuantityPlugin()],
    (dto: OrderParsePluginDto) => {
      return (
        dto.order.localStatus === OrderLocalStatus.PickingReady ||
        dto.order.localStatus === OrderLocalStatus.PickingInProgress
      );
    },
  );

  parseOrderPlugin.registerPlugins([new NormalizeItemsPlugin()]);

  parseOrderPlugin.registerPlugins(
    [new KeepItemsFromExistingOrderPlugin()],
    (dto: OrderParsePluginDto) => {
      return (
        dto.existingOrder !== undefined &&
        hasOrderLocalStatus(
          dto.existingOrder,
          OrderLocalStatus.HandoverInProgress,
          OrderLocalStatus.Paused,
        ) &&
        dto.order.localStatus !== OrderLocalStatus.HandoverReady
      );
    },
  );

  parseOrderPlugin.registerPlugins(
    [new GroupItemsPlugin()],
    (dto: OrderParsePluginDto) => {
      return !(
        dto.existingOrder !== undefined &&
        hasOrderLocalStatus(
          dto.existingOrder,
          OrderLocalStatus.HandoverInProgress,
          OrderLocalStatus.Paused,
        ) &&
        dto.order.localStatus !== OrderLocalStatus.HandoverReady
      );
    },
  );

  parseOrderPlugin.registerPlugins([
    new CountingOriginalForCancelAndStagedPlugin(),
  ]);

  parseOrderPlugin.registerPlugins([
    new RestrictedOrderCheckPlugin(),
    new SortItemsWithBagsPlugin(),
  ]);

  parseOrderPlugin.registerPlugins(
    [new DeleteOutstandingSyncsPlugin(syncSchedulerService)],
    (dto: OrderParsePluginDto) => {
      return hasOrderLocalStatus(
        dto.order,
        OrderLocalStatus.HandoverReady,
        OrderLocalStatus.HandoverInProgress,
        OrderLocalStatus.HandoverCompleted,
      );
    },
  );

  parseOrderPlugin.registerPlugins(
    [new CacheProductImagesPlugin(imageCachingService)],
    (dto: OrderParsePluginDto) => {
      return hasOrderLocalStatus(
        dto.order,
        OrderLocalStatus.PickingReady,
        OrderLocalStatus.HandoverReady,
      );
    },
  );

  parseOrderPlugin.registerPlugins(
    [new TrackEventHandoverReadyPlugin()],
    (dto: OrderParsePluginDto) => {
      return hasOrderLocalStatus(dto.order, OrderLocalStatus.HandoverReady);
    },
  );

  parseOrderPlugin.registerPlugins([new ManipulateOrderStatesPlugin()], () => {
    const { featureList } = useFeatureToggle();
    return featureList.value.manipulateOrderStates.active;
  });

  parseOrderPlugin.afterEach((dto, nextPluginName) =>
    loggerService.debug(`parseOrder pipeline: ${nextPluginName}`, dto),
  );

  return parseOrderPlugin;
}

const shouldUseExistingOrderAndCompletePipeline = (
  dto: OrderParsePluginDto,
) => {
  if (!dto.existingOrder) {
    return false;
  }

  if (
    hasOrderLocalStatus(
      dto.order,
      OrderLocalStatus.PickingInProgress,
      OrderLocalStatus.Paused,
    ) &&
    hasOrderLocalStatus(
      dto.existingOrder,
      OrderLocalStatus.PickingInProgress,
      OrderLocalStatus.Paused,
    )
  ) {
    return true;
  }

  if (
    hasOrderLocalStatus(
      dto.order,
      OrderLocalStatus.HandoverReady,
      OrderLocalStatus.HandoverInProgress,
    ) &&
    hasOrderLocalStatus(
      dto.existingOrder,
      OrderLocalStatus.HandoverInProgress,
      OrderLocalStatus.HandoverCompleted,
    )
  ) {
    return true;
  }

  return false;
};
