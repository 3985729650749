import { eventBusServicePlugin } from '@/features/core/event-bus';
import { ExecPluginFactory } from '@/features/core/plugin';
import { CheckinNotifyEvent } from '@/features/orders/checkin';
import { useCheckInOrders } from '@/features/orders';

export const orderCheckinNotifyPlugin = ExecPluginFactory.fn(() => {
  const { notifyAboutCheckIn } = useCheckInOrders();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  eventBusServicePlugin.get().on(CheckinNotifyEvent, notifyAboutCheckIn);
});
