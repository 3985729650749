import { LoadExistingOrderPlugin } from '@/features/orders/plugins/load-existing-order';
import { NormalizeOrderPlugin } from '@/features/orders/plugins/normalize-order';
import type {
  CompletePipelineWith,
  PipelinePlugin,
} from '@ads/plugin-pipeline';
import { Order } from '@/features/orders/entities';
import type { OrderParsePluginDto } from '@/features/orders/types';

/**
 * Use existingOrder as order and complete pipeline
 **/
export class UseExistingOrderAndCompletePipelinePlugin
  implements PipelinePlugin<OrderParsePluginDto>
{
  public readonly requiredPlugins = [
    LoadExistingOrderPlugin,
    NormalizeOrderPlugin,
  ];

  public execute(
    dataTransferObject: OrderParsePluginDto,
    completePipelineWith: CompletePipelineWith<OrderParsePluginDto>,
  ): OrderParsePluginDto {
    dataTransferObject.order = Order.from({
      ...dataTransferObject.existingOrder,
      actions: dataTransferObject.order.actions,
      status: dataTransferObject.order.status,
      localStatus: dataTransferObject.order.localStatus,
    });
    return completePipelineWith(dataTransferObject);
  }
}
