import './registerServiceWorker';

import { createApp } from 'vue';

import Notifications from '@kyvg/vue3-notification';
import { coreInitPlugin, corePlugin } from '@/features/core';
import { defaultRedactedKeys, loggerPlugin } from '@/features/core/logger';
import { featuresPlugin } from '@/features';
import { BarcodePatterns } from '@/features/barcode';
import { performanceTrackerPlugin } from '@/features/performance-tracker';
import { urlBuilderServicePlugin, urlPlugin } from '@/features/url';
import { configService } from './utils/config';
import { useFeatureToggle } from '@/features/feature-toggle';
import { i18n } from '@/i18n';
import App from './App.vue';
import { configSets } from '../environment-configurations';

const { featureList } = useFeatureToggle();

const app = createApp(App);
app.use(i18n);
app.use(urlPlugin);

app.use(loggerPlugin, () => ({
  loggerServicePlugin: {
    redactedKeys: defaultRedactedKeys,
  },
}));
app.use(performanceTrackerPlugin);
app.use(corePlugin, () => ({
  apiConfigPlugin: {
    apiPlugin: {
      baseURL: urlBuilderServicePlugin.get().getApiUrl(),
      config: configService,
      timeout: 60 * 1000,
    },
    productSearchApiPlugin: {
      baseURL: urlBuilderServicePlugin.get().getStoreFrontApiUrl(),
    },
  },
  empowerIdApiConfigPlugin: {
    empowerIdApiPlugin: {
      baseURL: urlBuilderServicePlugin.get().getAppOauthUrl(),
    },
  },
  authConfigPlugin: {
    authServicePlugin: {
      config: configService,
    },
  },
  environmentConfigurationPlugin: {
    configSets,
  },
}));

app.use(featuresPlugin, () => ({
  oauthPlugin: {
    oauthServicePlugin: configService,
  },
  barcodeConfigPlugin: {
    barcodePlugin: {
      patterns: {
        ...BarcodePatterns,
      },
    },
  },
  pushNotificationPlugin: {
    pushNotificationServicePlugin: {
      apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
      authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.VUE_APP_FIREBASE_APP_ID,
      measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
      vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY,
    },
  },
  dataSyncPlugin: {
    dataSyncServicePlugin: {
      dataUpdateInterval: 8 * 60 * 60 * 1000,
    },
  },
  appCrashPlugin: {
    appCrashServicePlugin: {
      skipAppCrashRedirect: !featureList.value.allowCrashReinstate.active,
    },
  },
  syncSchedulerPlugin: {
    syncSchedulerServicesPlugin: {
      syncSchedulerExecutorAdapterPlugin: {
        skipSW:
          process.env.NODE_ENV !== 'production' ||
          process.env.VUE_APP_SYNC_SCHEDULER_SKIP_SW !== undefined,
      },
    },
  },
  serviceWorkerPlugin: {
    appServiceWorkerServicePlugin: {
      skipSW:
        process.env.NODE_ENV !== 'production' ||
        process.env.VUE_APP_SYNC_SCHEDULER_SKIP_SW !== undefined,
    },
  },
  tourCrashPlugin: {
    tourCrashServicePlugin: {
      skipAppCrashRedirect:
        process.env.VUE_APP_DRIVER_APP_SKIP_CRASH_REDIRECT === 'true',
    },
  },
  reportsPlugin: {
    reportsServicePlugin: {
      appVersion: process.env.VUE_APP_VERSION,
    },
  },
}));

app.use(coreInitPlugin);

app.use(Notifications);

app.mount('#app');
