import { computed, ref, watch, WatchStopHandle } from 'vue';
import { useRoute } from 'vue-router';
import { BooleanNumber, StorageFilter } from '@/features/core/storage';
import { entityRepositoryPlugin } from '@/features/core/entity-repository';
import { errorPlugin } from '@/features/core/errors';
import { CheckCheckinOrdersError } from '../errors';
import { Order } from '../entities';
import { OrderLocalStatus, UseCheckInOrders } from '../types';

const isCheckedInOrdersAvailable = ref<boolean>(false);
const firstCheckedInOrder = ref<Order | undefined>();
const checkInBanner = ref<boolean>(true);
const checkinOrdersLength = ref<number>(0);

export function useCheckInOrders(): UseCheckInOrders {
  let watcher: WatchStopHandle | null = null;

  async function checkCheckedInOrders() {
    try {
      const filtering: Record<string, StorageFilter> = {
        'checkIn.isCheckedIn': { equals: BooleanNumber.True },
        localStatus: { anyOf: [OrderLocalStatus.HandoverReady] },
      };

      const checkInOrdersList = await entityRepositoryPlugin
        .get()
        .getAll(Order, {
          filter: filtering,
          sortBy: undefined,
          sortDir: undefined,
          offset: 0,
          limit: 3,
        });

      if (watcher) {
        watcher();
        watcher = null;
      }

      watcher = watch(
        checkInOrdersList,
        () => {
          isCheckedInOrdersAvailable.value = Boolean(
            checkInOrdersList.value.length,
          );
          firstCheckedInOrder.value = checkInOrdersList.value[0];
          checkinOrdersLength.value = checkInOrdersList.value.length;
        },
        { deep: true },
      );

      isCheckedInOrdersAvailable.value = Boolean(
        checkInOrdersList.value.length,
      );
      checkinOrdersLength.value = checkInOrdersList.value.length;

      firstCheckedInOrder.value = checkInOrdersList.value[0];
    } catch (error) {
      errorPlugin.get().handle(new CheckCheckinOrdersError());
    }
  }

  const route = useRoute();
  const showBanner = computed(
    () =>
      isCheckedInOrdersAvailable.value &&
      route.meta?.showCustomerCheckinBanner === true &&
      checkInBanner.value,
  );

  const notifyAboutCheckIn = (): void => {
    if (route?.meta?.showCustomerCheckinBanner !== true) {
      return;
    }

    void checkCheckedInOrders();
  };

  return {
    checkCheckedInOrders,
    notifyAboutCheckIn,
    isCheckedInOrdersAvailable,
    firstCheckedInOrder,
    showBanner,
    checkInBanner,
    checkinOrdersLength,
  };
}
