import { $t } from '@/i18n';
import { CompletePipelineWith, PipelinePlugin } from '@ads/plugin-pipeline';
import { isOrderPicking } from '@/features/orders';
import { useDynamicDialog } from '@/features/ui/composables/useDynamicDialog';
import { startConfirmationTexts } from '../composables';
import { OrderPluginDto } from '../types';

export class StartConfirmationPlugin implements PipelinePlugin<OrderPluginDto> {
  public async execute(
    dataTransferObject: OrderPluginDto,
    completePipelineWith: CompletePipelineWith<OrderPluginDto>,
  ): Promise<OrderPluginDto> {
    const {
      confirmButtonText,
      confirmPickingContent,
      confirmHandoverContent,
      confirmedTitle,
    } = startConfirmationTexts(dataTransferObject.order);

    const { confirm } = useDynamicDialog();
    const confirmed = await confirm({
      title: confirmedTitle,
      isTitleCentered: false,
      contentText: isOrderPicking(dataTransferObject.order)
        ? confirmPickingContent
        : confirmHandoverContent,
      confirmText: confirmButtonText,
      cancelText: $t('components.start-confirmation-popup.cancel-button.text'),
      e2e: `order-start-confirmation-popup-${dataTransferObject.order.id}`,
    });

    if (confirmed) {
      return dataTransferObject;
    }
    return completePipelineWith(dataTransferObject);
  }
}
