/**
 * Format for new version name - EntityName_Version
 * New version should be added to the end of the enum
 * */

export enum DBVersions {
  AuthData_1 = 1,
  User_1,
  StorageZone_1,
  Orders_1,
  Orders_2,
  Orders_3,
  Orders_4,
  Orders_5,
  Products_1,
  Products_2,
  Products_3,
  Products_4,
  Configuration_1,
  User_2,
  Sync_1,
  DataSync_1,
  User_3,
  Orders_6,
  ProcessedOrder_1,
  Products_5,
  DataFetchQueue_1,
  ImageCachingQueue_1,
  Orders_7,
  Tour_1,
  OrderMetadata_1,
  Orders_8,
  DeviceId_1,
  Tour_2,
  Tour_3,
  Logs_1,
  Orders_9,
  SurveyAnswer_1,
  Device_1,
  Logs_2,
  DriverNotification_1,
  DriverNotification_2,
  Tour_4,
  Sync_2,
}
